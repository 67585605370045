<template>
    <custom-table
        :runModeServer="false"
        :title="$t('pages.developer.videoManagement.downloader.detail.title')"
        :subTitle="$t('pages.developer.videoManagement.downloader.detail.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        @action="handleClickAction">
        <template v-slot:status="{ row: record }">
            <span :class="record.status && record.status.class ? record.status.class : 'bg-dark'" class="badge">{{ record.status ? record.status.text : "-" }}</span>
        </template>
        <template v-slot:title="{ row: record }">
            {{ stringLimit((record.stream_detail && record.stream_detail.original_title ? record.stream_detail.original_title : "-")) }}
        </template>
        <template v-slot:progress="{ row: record }">
            <el-progress :text-inside="true" :stroke-width="20" :percentage="record.task_percentage"/>
        </template>
    </custom-table>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "detail",
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("common.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("pages.developer.videoManagement.downloader.detail.cols.title"),
                    scopedSlots: {customRender: "title"}
                },
                {
                    name: this.$t("common.progress"),
                    scopedSlots: {customRender: "progress"}
                },
            ],
            actions: [
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
            table: {
                loading: false,
                data: [],
                pagination: {},
            }
        }
    },
    computed: {
        downloaderID() {
            return this.$route.params.id;
        }
    },

    created() {
        if (!this.downloaderID || !(this.downloaderID > 0)) {
            this.$router.push({
                path: "/developer/video-management/downloader"
            });
        }
    },
    mounted() {
        if (this.downloaderID && this.downloaderID > 0) {
            this.loadDownloader();
        }
    },
    methods: {
        loadDownloader() {
            this.table.loading = true;

            this.axios.get(this.endpoint.get('video_management_downloader', 'developer') + '/' + this.downloaderID).then((response) => {
                let data = response.data.data;

                this.table.data = (data.items ?? [])

                this.table.pagination = {
                    current: 1,
                    pageSize: 10,
                    total: this.table.data.length
                }
            }).catch(error => {
                if (error.response.data.message == 'record_not_found') {
                    this.$router.push({
                        path: "/developer/video-management/downloader"
                    });
                }
            }).finally(() => {
                this.table.loading = false;
            });
        },

        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.loadDownloader();
                    break;

                default:
                    break;
            }
        },
    }
}
</script>

<style scoped>

</style>